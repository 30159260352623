<template lang="pug">
  div(style="min-height: 70vh").mb-15
    div(v-if="allLoading==true").text-center.mt-4
      v-progress-circular(
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ).mt-15
    v-card.pa-5.ma-4(min-height="420" max-width="1200" v-if="state=='showRecord' && allLoading==false").mx-auto
      ais-instant-search(
        :search-client="searchClient",
        index-name="courseReferences",
        :routing="routing"
        :stalled-search-delay="200"
        :key="componentKey"
      ).pa-5
        ais-configure(
          v-bind="searchParameters"
          :query="search"
        )
        v-row(justify="center" no-gutters)
          v-col(cols="8" md="8")
            v-text-field(
              v-model.trim="search"
              rounded
              filled
              dense
              placeholder="Search All Courses"
              prepend-inner-icon="mdi-magnify"
            )
          v-col(cols="2").text-center
            v-btn(small color="success" @click="state='newCourse'").mt-1 Add course
        // FlashMessage is placed here so that it will show up over the v-card
        FlashMessage(:position="'left bottom'", :strategy="'multiple'")
        ais-hits(ref="aisHit")
          div(slot-scope="{ items }")
            v-list(@open="opened(items)")
              v-list-group(
                v-for="(item,i) in items"
                :key="item.id"
              )
                template(v-slot:activator)
                  v-list-item-content
                    v-list-item-title.font-weight-medium.d-flex
                      div {{item.course}}
                      div.ml-2.font-weight-regular(v-if="$vuetify.breakpoint.mdAndUp") {{item.description}}
                      div.ml-auto
                        v-icon(small).ml-4 mdi-link
                        span.caption.ml-1 {{item.ref.length}}
                div(v-if="editing==item.course").mb-n3.mt-1
                  div.caption Edit Course Code
                  v-row
                    v-col(cols="4")
                      v-select(
                        required
                        v-model.trim="changeCourseShortName"
                        :rules="rules"
                        placeholder="Course Short Name"
                        :items="selectShortName"
                        rounded
                        filled
                        dense
                        :disabled = "dataLoading"
                      )
                    v-col(cols="4" v-if="changeCourseShortName=='Others:'")
                      v-text-field(
                        required
                        v-model.trim="changeOthers"
                        :rules="rules"
                        placeholder=""
                        rounded
                        filled
                        dense
                        :disabled = "dataLoading"
                      )
                    v-col(cols="4")
                      v-text-field(
                        required
                        v-model.trim="changeCourseNumber"
                        :rules="rules"
                        placeholder="Course Number"
                        type="number"
                        rounded
                        filled
                        dense
                        :disabled = "dataLoading"
                      )                  
                  div.caption.mt-n2 Edit Course Title
                  v-text-field(
                    required
                    v-model.trim="changeDescription"
                    placeholder="New Course Title"
                    rounded
                    filled
                    :rules="rules"
                    dense
                    :disabled = "dataLoading"
                  )
                  v-btn(
                    color="grey"
                    small
                    depressed
                    rounded
                    dark
                    @click="editing=null"
                    :disabled="dataLoading"
                  ).mb-6.mr-3 Cancel
                  v-btn(
                    color="primary"
                    small
                    depressed
                    rounded
                    :disabled = "dataLoading || ((changeDescription==item.description) && (changeCourseShortName==item.name) && changeCourseNumber==item.number)"
                    @click="editCourse(item.id)"
                  ).mb-6 Save
                v-list-item(v-else)
                  v-btn(small color="info" @click="editing=item.course, changeCourse=item.course; changeDescription=item.description; changeCourseShortName=item.name; changeCourseNumber=item.number" :disabled="dataLoading") Edit {{item.course}}
                  v-btn(small color="error" @click="courseId=item.id; courseName=item.course; state='deleteCourse'" :disabled="dataLoading").ml-5 Delete {{item.course}}
                div.font-weight-bold.subtitle.ml-4(v-if="$vuetify.breakpoint.smAndDown") {{item.description}}
                div.mx-10.mt-5
                  h5.subtitle-2 Add Reference for {{item.course}} (Current: {{item.ref.length}} reference/s)
                  div.caption Reference Entry *
                  v-textarea(
                    placeholder="Reference Entry"
                    rounded
                    filled
                    dense
                    auto-grow
                    rows="3"
                    v-model.trim="newRef[i]"
                    :disabled = "dataLoading"
                  )
                  div.mt-n2.caption Link (Optional)
                  v-text-field(
                    placeholder="Link"
                    rounded
                    filled
                    dense
                    v-model.trim="newLink[i]"
                    :disabled = "dataLoading"
                  )
                  v-btn(
                    color="success"
                    small
                    depressed
                    rounded
                    :disabled = "dataLoading"
                    @click="addRef(item.id, i)"
                  ).mb-2 Add Reference
                div.ml-8.mt-6.font-weight-bold.subtitle-2 References
                v-list-item-group(v-if="item.ref.length>0").ml-4.mb-6
                  v-list-item(v-for="(v, key) in item.ref" :key="key")
                    v-list-item-icon
                      v-icon mdi-arrow-right-bottom-bold
                    v-list-item-action.text-muted.mr-1 Ref. {{key+1}}:
                    v-list-item-content
                      a(:href="v.split('(#link)')[1]" target="_blank") {{v.split("(#link)")[0]}}
                    v-list-item-action
                      v-btn(x-small color="info" @click="showEditRef(item.id, v, i)" :disabled="dataLoading") Edit
                    v-list-item-action
                      v-btn(x-small color="error" @click="deleteConfirm(item.id, v)" :disabled="dataLoading" :loading="dataLoading").ml-5.mr-6 Delete
                v-list-item-content(v-else).text-muted.body-2.ml-10.mb-6 No listed references

        ais-pagination
          div(
            slot-scope="{currentRefinement, nbPages,pages,isFirstPage,isLastPage,refine,createURL}"
          )
            .text-center.mt-5
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isFirstPage",
                @click.prevent="refine(0); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                @click.prevent="refine(currentRefinement - 1); toTop()",
                outlined
              )
                v-icon mdi-menu-left
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-for="page in pages.slice(0,4)",
                :key="page",
                :outlined="page != currentRefinement",
                @click.prevent="refine(page); toTop()"
              ) {{ page + 1 }}
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(currentRefinement + 1); toTop()",
                outlined
              )
                v-icon mdi-menu-right
              v-btn.mx-1(
                color="primary",
                x-small,
                fab,
                v-if="!isLastPage",
                @click.prevent="refine(nbPages); toTop()",
                outlined
              )
                v-icon mdi-chevron-double-right
    //- editRef
    v-card(v-show="state=='editingRefLink'" width="800").mx-auto.mt-8
      v-card-title.headline.grey.lighten-2 Edit Reference
      .pa-5
        h5.subtitle-2 Editing Ref {{i+1}}
        div.caption Reference Entry
        v-textarea(
          placeholder="Reference Entry"
          rounded
          filled
          dense
          auto-grow
          rows="3"
          v-model.trim="editingRef"
          :disabled = "dataLoading"
        )
        div.mt-n2.caption Link
        v-text-field(
          placeholder="Link"
          rounded
          filled
          dense
          v-model.trim="editingLink"
          :disabled = "dataLoading"
        )
        div.text-end
          v-btn(
            color="grey"
            dark
            small
            depressed
            rounded
            :disabled = "dataLoading"
            @click="state='showRecord'"
          ).mb-2.mr-4 Cancel
          v-btn(
            color="success"
            small
            depressed
            rounded
            :disabled = "dataLoading"
            @click="editRef()"
          ).mb-2 Save Reference 
    //- deleteRef
    v-card(v-show="state=='deleteRef'" width="800").mx-auto.mt-8
      v-card-title.headline.grey.lighten-2 Delete Course
      .pa-5
        p.font-weight-light Are you sure do you want to delete the reference <strong> {{ref.split("(#link)")[0]}} </strong>?
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="state='showRecord'" :disabled="dataLoading") Cancel
        v-btn(small color="error" @click="deleteRef()" :loading="dataLoading").ml-5 Delete
    //- Delete Course (deleteCourse)
    v-card(v-if="state=='deleteCourse'" width="800").mx-auto.mt-8
      v-card-title.headline.grey.lighten-2 Delete Course
      .pa-5
        p.font-weight-light Are you sure do you want to delete the course <strong>{{ courseName }}</strong>?
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="state='showRecord'" :disabled="dataLoading") Cancel
        v-btn(small color="error" @click="deleteCourse(courseId)" :loading="dataLoading").ml-5 Delete
    //- newCourse
    v-card(width="800" v-if="state=='newCourse'").mx-auto.mt-8
      v-card-title.headline.grey.lighten-2 Add Course
      v-card-actions.px-5.mt-4
        div.h5 {{instiName}}
        v-spacer
        div(v-if="refList.length>7")
          v-btn(color="grey", text, @click="instiName=''; instiId=''; state='showRecord'" :disabled="dataLoading") Cancel
          v-btn(color="success" @click="createCourse()" :loading="dataLoading" :disabled="!courseNumber || !courseShortName || !newCourseTitle") Add course
      .pa-5.pt-0
        div.subtitle Course Code *
        p.caption.muted.ml-3 e.g. Math 21, Bio 1, Physics 10
        //- v-text-field(
        //-   required
        //-   v-model.trim="newCourse"
        //-   :rules="rules"
        //-   placeholder="Course"
        //-   rounded
        //-   filled
        //-   dense
        //- )
        v-row
          v-col(cols="4")
            v-select(
              required
              v-model.trim="courseShortName"
              :rules="rules"
              placeholder="Course Short Name"
              :items="selectShortName"
              rounded
              filled
              dense
            )
          v-col(cols="4" v-if="courseShortName=='Others:'")
            v-text-field(
              required
              v-model.trim="others"
              :rules="rules"
              placeholder=""
              rounded
              filled
              dense
            )
          v-col(cols="4")
            v-text-field(
              required
              v-model.trim="courseNumber"
              :rules="rules"
              placeholder="Course Number"
              type="number"
              rounded
              filled
              dense
            )
        div.subtitle Course Title *
        p.caption.muted.ml-3 e.g. Elementary Analysis
        v-text-field(
          required
          v-model.trim="newCourseTitle"
          :rules="rules"
          placeholder="Course Title"
          rounded
          filled
          dense
        )
        div.mb-2.subtitle Offering Institute *
        v-select(
          required
          v-model.trim="instiName"
          :rules="rules"
          placeholder="Offering Institute"
          :items="institutions"
          rounded
          filled
          dense
        )
        v-list.mt-n3
          v-subheader References (Optional)
          v-subheader(v-if="refList.length!=0").mt-n5
            v-btn(color="error" x-small @click="refList=[]") Clear References
          v-list-item(
            v-for="(item, i) in refList"
            :key="i"
          ).my-n3
            v-list-item-icon
              v-icon mdi-arrow-right-bottom-bold
            v-list-item-action.text-muted.mr-1 Ref. {{i+1}}:
            v-list-item-content
              v-list-item-title {{item.split('(#link)')[0]}} 
                span
                  v-chip(v-if="item.split('(#link)')[1]==''" x-small).caption.text-muted No link
            v-list-item-action
              v-btn(color="error" x-small @click="refList.splice(i, 1)" :disabled="dataLoading") delete
          v-list-item
            v-list-item-icon
              v-icon mdi-arrow-right-bottom-bold
            v-list-item-content
              div.caption Reference Entry *
              v-textarea(
                required
                v-model.trim="refItem"
                placeholder="Reference Entry"
                rounded
                filled
                auto-grow
                rows="3"
                dense
                :disabled="dataLoading"
              )
              div.mt-n2.caption Link (Optional)
              v-text-field(
                placeholder="Link"
                rounded
                filled
                dense
                v-model.trim="refItemLink"
                :disabled = "dataLoading"
              )
              div
                v-btn(
                  color="success"
                  small
                  depressed
                  rounded
                  :disabled="(!refItem) || dataLoading"
                  @click="appendRef()"
                ) Add Reference
      v-divider
      v-card-actions.px-5.pb-5
        v-spacer
        v-btn(color="grey", text, @click="state='showRecord'" :disabled="dataLoading") Cancel
        v-btn(color="success" @click="createCourse()" :loading="dataLoading" :disabled="!courseNumber || !courseShortName || !newCourseTitle") Add course                      
</template>

<script>
import firebase from "firebase/app";
import "firebase/functions";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";
import { history } from "instantsearch.js/es/lib/routers";
import { simple } from "instantsearch.js/es/lib/stateMappings";
import { db } from "../main";

let createCourse = firebase.functions().httpsCallable("createCourse");
let deleteCourse = firebase.functions().httpsCallable("deleteCourse");
let editCourse = firebase.functions().httpsCallable("editCourse");
let addRef = firebase.functions().httpsCallable("addRef");
let deleteRef = firebase.functions().httpsCallable("deleteRef");
let editRef = firebase.functions().httpsCallable("editRef");

export default {
  data() {
    return {
      changeCourseShortName: "",
      changeOthers: "",
      changeCourseNumber: 0,
      courseShortName: "",
      courseNumber: 0,
      others: "",
      componentKey: 0,
      i: 0,
      editingRef: "",
      editingLink: "",
      ref: "",
      newRef: [],
      newLink: [],
      matchCourse: [],
      changeDescription: "",
      changeCourse: "",
      editing: null,
      instiName: "",
      institutions: [
        "Institute of Biology",
        "Institute of Chemistry",
        "Institute of Environmental Science and Meteorology",
        "Institute of Mathematics",
        "Marine Science Institute",
        "Materials Science and Engineering Program",
        "National Institute of Geological Sciences",
        "National Institute of Molecular Biology and Biotechnology",
        "National Institute of Physics",
      ],
      rules: [(v) => !!v || "This field is required"],
      dataLoading: false,
      state: "showRecord",
      newCourseTitle: "",
      refItem: "",
      refItemLink: "",
      newCourse: "",
      refList: [],
      limit: 12,
      searchClient: null,
      searchParameters: {
        hitsPerPage: 12,
      },
      routing: {
        router: history(),
        stateMapping: simple(),
      },
      search: "",
      reference: [],
      course: "",
      courseId: "",
      courseName: "",
      allLoading: false,
      selectShortName: [
        "App Physics",
        "Bio",
        "Chem",
        "CWTS",
        "Env. Sci.",
        "Math",
        "MBB",
        "Meteo",
        "Microbio",
        "MS",
        "MSE",
        "GE",
        "Geog",
        "Geol",
        "Physics",
        "TMEM",
        "Others:",
      ],
    };
  },

  async created() {
    this.collection();
  },

  mounted() {
    this.flashMessage.setStrategy("multiple");
  },

  methods: {
    reloadSearch() {
      this.allLoading = true;
      this.search = "";
      setTimeout(() => {
        this.componentKey += 1;
        this.allLoading = false;
      }, 2000);
    },
    showEditRef(id, v, i) {
      this.courseId = id;
      this.editingRef = v.split("(#link)")[0];
      this.editingLink = v.split("(#link)")[1];
      this.i = i;
      this.ref = v;
      this.state = "editingRefLink";
    },
    async editRef() {
      this.dataLoading = true;
      const finalRef = this.editingRef + "(#link)" + this.editingLink;
      await editRef({
        id: this.courseId,
        ref: this.ref,
        newRef: finalRef,
      });
      this.flashMessage.success({
        title: "Edited Reference",
        message: "You have successfully edited the reference.",
        icon: "mdi-pencil",
        iconColor: "success",
      });
      this.state = "showRecord";
      this.dataLoading = false;
      this.reloadSearch();
    },
    deleteConfirm(id, ref) {
      this.courseId = id;
      this.ref = ref;
      this.state = "deleteRef";
    },
    async deleteRef() {
      this.dataLoading = true;
      await deleteRef({ id: this.courseId, ref: this.ref });
      // show success flashmessage when deleteRef returns
      // TODO: this does not check if deleteRef is successful or not. It's currently only checking if the function
      // returned. Would be better to check the return code of deleteRef, or make deleteRef return something to show
      // if the operation is successful or not
      this.state = "showRecord";
      this.reloadSearch();
      this.flashMessage.error({
        title: "Deleted Reference",
        message: "You have successfully deleted the reference.",
        icon: "mdi-delete",
        iconColor: "error",
      });
      this.dataLoading = false;
    },
    async addRef(courseId, arrayId) {
      this.dataLoading = true;
      if (this.newRef[arrayId] && this.newRef[arrayId] != "") {
        if (this.newLink[arrayId] && this.newLink[arrayId] != "") {
          var link = this.newLink[arrayId];
        } else {
          link = " ";
        }
        await addRef({
          id: courseId,
          ref: `${this.newRef[arrayId]}(#link)${link}`,
        });
        // show success flashmessage when addref returns
        // TODO: this does not check if addRef is successful or not. It's currently only checking if the function
        // returned. Would be better to check the return code of addRef, or make addRef return something to show
        // if the operation is successful or not
        this.newRef[arrayId] = "";
        this.newLink[arrayId] = "";
        this.reloadSearch();
        await this.flashMessage.success({
          title: "Added Reference",
          message: "You have successfully added a reference.",
          icon: "mdi-pencil",
          iconColor: "success",
        });
      } else {
        this.$store.dispatch("setSnackbar", {
          text: "  Please accomplish required fields!",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
      }
      this.dataLoading = false;
    },
    async editCourse(id) {
      if (this.changeCourseShortName == "Others:") {
        this.finalName = this.changeOthers;
      } else {
        this.finalName = this.changeCourseShortName;
      }
      this.changeCourse = this.finalName + " " + this.changeCourseNumber;
      this.dataLoading = true;
      this.checkDuplicate(this.changeCourse);
      await editCourse({
        id: id,
        name: this.finalName,
        number: Number(this.changeCourseNumber),
        course: this.changeCourse,
        description: this.changeDescription,
      });
      this.flashMessage.success({
        title: "Edited Course",
        message: "You have successfully edited a course.",
        icon: "mdi-pencil",
        iconColor: "success",
      });
      this.editing = null;
      this.dataLoading = false;
      this.reloadSearch();
    },
    async deleteCourse(id) {
      this.dataLoading = true;
      await deleteCourse({ id: id });
      this.flashMessage.error({
        title: "Deleted Course",
        message: "You have successfully deleted the course.",
        icon: "mdi-delete",
        iconColor: "error",
      });
      this.state = "showRecord";
      this.dataLoading = false;
      this.reloadSearch();
    },
    async createCourse() {
      if (this.courseShortName && this.newCourseTitle && this.courseNumber) {
        if (this.courseShortName == "Others:") {
          this.finalName = this.others;
        } else {
          this.finalName = this.courseShortName;
        }
        this.newCourse = this.finalName + " " + this.courseNumber;
        this.dataLoading = true;
        this.checkDuplicate(this.newCourse);
        const data = {
          name: this.finalName,
          number: Number(this.courseNumber),
          course: this.newCourse,
          institute: this.instiName,
          description: this.newCourseTitle,
          ref: this.refList,
        };
        await createCourse(data);
        this.$store.dispatch("setSnackbar", {
          text: "  New course has been successfully added!",
          icon: "mdi-check",
          iconColor: "success",
        });
        this.dataLoading = false;
        this.finalName = "";
        this.courseNumber = "";
        this.others = "";
        this.newCourseTitle = "";
        this.refList = [];
        this.refItem = "";
        this.state = "showRecord";
        this.reloadSearch();
      } else {
        this.$store.dispatch("setSnackbar", {
          text: "  Invalid. Please enter data on remaining fields",
          icon: "mdi-block-helper",
          iconColor: "error",
        });
      }
    },
    async checkDuplicate(course) {
      await this.$bind(
        "matchCourse",
        db.collection("courseReferences").where("course", "==", course).limit(1)
      ).then(() => {
        if (this.matchCourse.length > 1) {
          this.$store.dispatch("setSnackbar", {
            text: `  Duplicate found. ${course} already exists on the collection.`,
            icon: "mdi-block-helper",
            iconColor: "error",
          });
          this.dataLoading = false;
          return;
        }
      });
    },
    appendRef() {
      if (this.refItem != "") {
        this.refList.push(this.refItem + "(#link)" + this.refItemLink);
      }
      this.refItem = "";
      this.refItemLink = "";
    },
    link(link) {
      window.open("//" + link, "_blank");
    },

    toTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },

    collection() {
      const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
        server: {
          apiKey: "UClwVI81Eboli8TAnJdUv95cAoZkPCY9", // Be sure to use an API key that only allows search operations
          nodes: [
            {
              host: "ehubstorage.cslib.upd.edu.ph",
              port: "443",
              protocol: "https",
            },
          ],
          cacheSearchResultsForSeconds: 0, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
        additionalSearchParameters: {
          queryBy: "course, description",
          sort_by: "_text_match:desc,number:asc",
        },
      });
      this.searchClient = typesenseInstantsearchAdapter.searchClient;
    },
  },
};
</script>

<style scoped>
.border {
  border: 5px solid red;
}
</style>
